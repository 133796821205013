var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "checkout-payment-container" },
    [_c("payment-method-options", { attrs: { total: _vm.cartItemsTotal() } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }